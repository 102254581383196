import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { LeaveModal } from '../modals';
import style from '../../styling/general.module.scss';

import { Hero, Appointments, Doctors, Services, Practical, FooterContent } from '../'
import { Nav, Logo } from '../elements';

const Home = observer(() => {
  const [modal, setModal] = useState(false);

  const refs = [
    {
      link: 'Welkom',
      ref: useRef(),
    },
    {
      link: 'Afspraken',
      ref: useRef(),
    },
    {
      link: 'Huisartsen',
      ref: useRef(),
    },
    {
      link: 'Services',
      ref: useRef(),
    },
    {
      link: 'Praktisch',
      ref: useRef(),
    }
  ]


  return(
    <>
      <header className={style.header} ref={refs[0].ref}>
        <Logo />
        <Nav refs={refs}/>
        <Hero />
      </header>
      <main className={style.main}>
        {
          modal  && <LeaveModal closeModal={() => setModal(false)} /> 
        }
        <Appointments ref={refs[1].ref} />
        <Doctors ref={refs[2].ref} />
        <Services ref={refs[3].ref} />
        <Practical ref={refs[4].ref} />
      </main>
      <footer className={style.footer}>
        <FooterContent />
      </footer>
    </>
  )

});

export default Home;
